import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { LayoutMDX } from '@talves/gatsby-theme-site-layout';
import { PostsWithDataSearch } from '@talves/gatsby-theme-site-garden';
import { Box } from '../provider';
export const _frontmatter = {
  "title": "Tech I'm working on",
  "description": "Current Posts are typically stuff I'm working on or researching."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutMDX;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Box sx={{
      fontSize: [2, 3, 4]
    }} mdxType="Box">
  Tony here.
    </Box>
    <Box as="div" sx={{
      fontSize: [1, 2, 3],
      ml: '1em'
    }} mdxType="Box">
I love to check out new technologies. You'll find my most current Posts are typically stuff I'm working on or researching. I spend a lot of time doing Javascript web applications using cloud services.
    </Box>
    <Box as="div" sx={{
      mt: [1, 2, 3]
    }} mdxType="Box">
  <Box as="h2" mdxType="Box">Recent Tutorials</Box>
    <PostsWithDataSearch search="tutorial" max={3} mdxType="PostsWithDataSearch" />
    </Box>
    <Box as="div" sx={{
      mt: [1, 2, 3]
    }} mdxType="Box">
  <Box as="h2" mdxType="Box">Recent Notes</Box>
    <PostsWithDataSearch search="notes" max={5} mdxType="PostsWithDataSearch" />
    </Box>
    <Box as="div" sx={{
      mt: [1, 2, 3]
    }} mdxType="Box">
  <Box as="h2" mdxType="Box">Recent Gatsby</Box>
    <PostsWithDataSearch search="gatsby" max={3} mdxType="PostsWithDataSearch" />
    </Box>
    <Box as="div" sx={{
      mt: [1, 2, 3]
    }} mdxType="Box">
  <Box as="h2" mdxType="Box">Recent Jamstack</Box>
    <PostsWithDataSearch search="jamstack" max={3} mdxType="PostsWithDataSearch" />
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      